var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quiz-card"},[_c('div',{staticClass:"quiz-card-inner"},[_c('div',{staticClass:"quiz-card-header"},[_c('page-title',{attrs:{"tag":"div","size":"16"}},[_vm._v(" "+_vm._s(_vm.$t('question'))+" "+_vm._s(_vm.index + 1)+" ")]),_c('p',{staticClass:"text-gray-300"},[_vm._v(" "+_vm._s(_vm.data.question)+" ")])],1),(_vm.showResult)?_c('interview-quiz',{attrs:{"readonly":"","show-result":"","options":_vm.data.tests,"values":_vm.data.answer}}):_c('interview-quiz',{attrs:{"readonly":"","options":_vm.data.tests.map(function (ref) {
        var test_id = ref.test_id;
        var text = ref.text;

        return ({
          label: text,
          value: test_id
        });
}),"values":_vm.data.tests
          .filter(function (answer) { return !!answer.correct; })
          .map(function (ref) {
                  var test_id = ref.test_id;

                  return test_id;
})}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }