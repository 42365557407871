var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"centered":"","width":"620px","visible":_vm.visible,"footer":null},on:{"cancel":_vm.handleClose}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-icon',{staticStyle:{"font-size":"24px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"}),_c('page-title',{attrs:{"tag":"h2","size":"16"}},[_vm._v(" "+_vm._s(_vm.info.question)+" ")]),(_vm.info.video && _vm.info.video.recognitionText)?[_c('p',{staticClass:"mb-5 text-size-10"},[_vm._v(" "+_vm._s(_vm.$t('recognized_text_by'))+" ")]),_c('p',{staticClass:"text-size-14"},[_vm._v(" "+_vm._s(_vm.info.video.recognitionText)+" ")])]:_vm._e(),_c('a-row',{attrs:{"type":"flex","gutter":[10, 10]}},[_c('a-col',{attrs:{"sm":{ span: 20 }}},[_c('div',{staticClass:"rate-container"},[_c('b',[_vm._v(" "+_vm._s(((_vm.$t('answer_rating')) + ":"))+" ")]),_c('div',{staticClass:"rate-buttons"},[_c('a-button',{class:[
                'px-0',
                'mr-10',
                { 'rate-button-current': _vm.info.rate === 'good' }
              ],attrs:{"type":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRate('good')}}},[_c('icon-check',{staticClass:"mr-5 small"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('good'))+" ")])],1),_c('a-button',{class:[
                'px-0',
                'mr-10',
                { 'rate-button-current': _vm.info.rate === 'middling' }
              ],attrs:{"type":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRate('middling')}}},[_c('icon-question-round',{staticClass:"mr-5 small"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('middling'))+" ")])],1),_c('a-button',{class:[
                'px-0',
                'mr-10',
                { 'rate-button-current': _vm.info.rate === 'bad' }
              ],attrs:{"type":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRate('bad')}}},[_c('icon-remove',{staticClass:"mr-5 small"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('bad'))+" ")])],1)],1)])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }