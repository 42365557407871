var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billing-info"},[_c('app-button',{on:{"click":_vm.toggleFormVisible}},[_vm._v(" "+_vm._s(_vm.isFormVisible ? _vm.$t('cancel') : _vm.$t('change_billing_info'))+" ")]),(_vm.isFormVisible)?_c('card',{staticClass:"mt-20"},[_c('page-title',{staticClass:"stripe-form-title",attrs:{"tag":"h3","size":"16"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('billing_information'))+" ")]),_c('div',{staticClass:"stripe-form-toggle-business"},[_c('a-switch',{attrs:{"size":"small"},model:{value:(_vm.isBusiness),callback:function ($$v) {_vm.isBusiness=$$v},expression:"isBusiness"}}),_vm._v(" "+_vm._s(_vm.$t('business'))+" ")],1)]),_c('a-row',{attrs:{"gutter":[{ sm: 10, xs: 0 }, 10]}},[_c('a-col',{attrs:{"sm":12,"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.userName.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.name'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userName.value),expression:"userName.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.userName.error }
            ],attrs:{"type":"text","placeholder":_vm.$t('placeholders.name')},domProps:{"value":(_vm.userName.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userName, "value", $event.target.value)}}})])]),(_vm.isBusiness)?_c('a-col',{attrs:{"sm":12,"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.companyName.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.company_name'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyName.value),expression:"companyName.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.companyName.error }
            ],attrs:{"type":"text","placeholder":_vm.$t('placeholders.company_name')},domProps:{"value":(_vm.companyName.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyName, "value", $event.target.value)}}})])]):_vm._e(),_c('a-col',{attrs:{"sm":12,"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.userEmail.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.email'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userEmail.value),expression:"userEmail.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.userEmail.error }
            ],attrs:{"type":"email","placeholder":_vm.$t('placeholders.email')},domProps:{"value":(_vm.userEmail.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userEmail, "value", $event.target.value)}}})])]),_c('a-col',{attrs:{"sm":12,"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.userCountry.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.country'))+" ")]):_vm._e(),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.userCountry.value),expression:"userCountry.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.userCountry.error }
            ],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.userCountry, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.countries),function(country){return _c('option',{key:country.code,domProps:{"value":country.code}},[_vm._v(" "+_vm._s(country.name)+" ")])}),0),_c('icon-arrow-down',{staticClass:"billing-input-arrow"})],1)]),_c('a-col',{attrs:{"sm":12,"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.userCity.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.сity'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userCity.value),expression:"userCity.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.userCity.error }
            ],attrs:{"type":"text","placeholder":_vm.$t('placeholders.сity')},domProps:{"value":(_vm.userCity.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userCity, "value", $event.target.value)}}})])]),_c('a-col',{attrs:{"sm":12,"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.userState.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.state'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userState.value),expression:"userState.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.userState.error }
            ],attrs:{"type":"text","placeholder":_vm.$t('placeholders.state')},domProps:{"value":(_vm.userState.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userState, "value", $event.target.value)}}})])]),_c('a-col',{attrs:{"sm":12,"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.userAddress.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.address'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userAddress.value),expression:"userAddress.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.userAddress.error }
            ],attrs:{"type":"text","placeholder":_vm.$t('placeholders.address')},domProps:{"value":(_vm.userAddress.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userAddress, "value", $event.target.value)}}})])]),_c('a-col',{attrs:{"sm":12,"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.userZip.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.zip'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userZip.value),expression:"userZip.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.userZip.error }
            ],attrs:{"type":"text","placeholder":_vm.$t('placeholders.zip')},domProps:{"value":(_vm.userZip.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userZip, "value", $event.target.value)}}})])]),(_vm.isBusiness && _vm.isEU)?_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"billing-input-wrapper"},[(_vm.companyVatNumber.value.length)?_c('span',{staticClass:"billing-input-label"},[_vm._v(" "+_vm._s(_vm.$t('placeholders.vat_number'))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyVatNumber.value),expression:"companyVatNumber.value"}],class:[
              'billing-input',
              { 'billing-input-error': _vm.companyVatNumber.error }
            ],attrs:{"type":"text","placeholder":_vm.$t('placeholders.vat_number')},domProps:{"value":(_vm.companyVatNumber.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.companyVatNumber, "value", $event.target.value)},_vm.validateVatNumber]}}),_c('span',{staticClass:"billing-input-error-message"},[_vm._v(" "+_vm._s(_vm.$t('incorrect_vat_number'))+" ")])])]):_vm._e(),_c('a-col',{attrs:{"span":24}},[_c('app-button',{attrs:{"loading":_vm.isChangeBillingInfoLoading},on:{"click":_vm.onSaveInfo}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }